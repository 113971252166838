import React, { useState, useCallback, useEffect } from "react";

import {
  Box,
  Checkbox,
  Grid,
  TextField,
  Stepper,
  StepLabel,
  Step,
  useMediaQuery,
} from "@mui/material";
import {
  SBox1,
  SBox2,
  SBox3,
  SBox4,
  SBox5,
  SBox7,
  SBox6,
  SButton1,
  SButton2,
  SButton3,
  STypography1,
  STypography10,
  STypography11,
  STypography2,
  STypography3,
  STypography4,
  STypography5,
  STypography6,
  STypography7,
  STypography8,
  STypography9,
  STypography12,
  STypography13,
  STypography14,
} from "../assets/style/Styles";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import cover1 from "../assets/images/SBA-001.jpg";
import { useSearchParams } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import img4 from "../assets/images/imgFour.png";
import mobBanner from "../assets/images/mobBanner.jpg";
import cover2 from "../assets/images/WhatsApp Image 2024-04-12 at 6.52.42 PM.jpeg";
import { TailSpin } from "react-loader-spinner";

function RegistrationForm() {
  const matchmd = useMediaQuery("(min-width:1050px)");
  const matchsm = useMediaQuery("(min-width:600px)");
  const matchsmb = useMediaQuery("(max-width:450px)");
  const [jpId, setJpId] = useState("");
  const [bannerImg, setBannerImg] = useState("");
  const [count, setCount] = useState([]);
  const [newshow, setNewshow] = useState(false);
  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [orgid, setOrgId] = useState("");
  const [orgNo, setOrgNo] = useState("");
  const [orgName, setOrgName] = useState("");
  const [orgAddress, setOrgAddress] = useState("");
  const [newState, setNewState] = useState("");
  const [newDistrict, setNewDistrict] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [cinNo, setCinNo] = useState("");
  const [newName, setNewName] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [newMobile, setNewMobile] = useState("");
  const [jobrole, setJobrole] = useState([]);

  const [obj, setObj] = useState({});

  const [submitform, setSubmitform] = useState(false);
  const [submitformOne, setSubmitformOne] = useState(false);
  const [nextStep, setNextStep] = useState(false);
  const [regId, setRegId] = useState("");
  const [showFinal, setShowFinal] = useState(false);
  const [universityList, setUniversityList] = useState([]);
  const [educationList, setEducationList] = useState([]);
  const [specList, setSpecList] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [industryList, setIndustryList] = useState([]);
  const [desiList, setDesiList] = useState([]);
  const [skillList, setSkillList] = useState([]);

  const [mobileNo, setMobileNo] = useState("");
  const [currentplan, setCurrentplan] = useState("");
  const [certificateLink, setCertficateLink] = useState("");
  const [error, setError] = React.useState(null);
  const [finalmsg, setFinalmsg] = useState({
    mindset_phrase: "Growth Oriented Entrepreneur",
    mindset_sentense:
      "You know how to grow something that is in its early stages. Focus on goal driven growth.",
    mindset_rolemodel: "Anand Mahindra",
  });
  const endOfQ12022 = dayjs("2007-03-31T23:59:59.999");
  ///modal///////////
  const [shouldScrollInViewport, setShouldScrollInViewport] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [permission, setPermission] = useState(false);
  // const closeModal = useCallback(() => setIsOpen(false), [setIsOpen]);
  // const setShouldScrollInViewportAndOpen = () => {
  //   setShouldScrollInViewport(false);
  //   setIsOpen(true);
  // };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open1, setOpen1] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);

  const [jobTitle, setJobTitle] = useState([
    {
      job_title: "",
      job_role: "",
      job_desc: "",
      count: "",
    },
  ]);
  const [searchParams, setSearchParams] = useSearchParams();
  const params = {};
  searchParams.forEach((value, key) => {
    params[key] = value;
  });
  console.log(params);
  const query1 = searchParams.get("jfid");

  console.log("utm id jfid:", query1);
  const stepperStyle = {
    "& .Mui-active": {
      "&.MuiStepIcon-root": {
        color: "#FF7A00",
      },
    },
    "& .Mui-completed": {
      "&.MuiStepIcon-root": {
        color: "#FF7A00",
      },
    },
  };

  const steps = ["Organisation Details", "Job Requirements Details"];
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const isStepSkipped = (step) => {
    return skipped.has(step);
  };
  const addFields = (e) => {
    e.preventDefault();
    let newfield = {
      job_title: "",
      job_role: "",
      job_desc: "",
      count: "",
    };
    setJobTitle([...jobTitle, newfield]);
  };

  const handleForm = (index, name, value) => {
    let data = [...jobTitle];
    data[index][name] = value;
    setJobTitle(data);
  };

  const removeField = (index) => {
    let data = [...jobTitle];
    data.splice(index, 1);
    setJobTitle(data);
  };

  const fetchBanner = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/banner_display`,
        { params: { jf_id: query1 } }
      )
      .then((res) => {
        console.log("banner", res.data.banner.split(" ").reverse());
        setCount(res.data.banner.split(" ").reverse());
        setBannerImg(res.data.banner);
      })
      .catch((err) => {
        console.log("banner err", err);
      });
  };

  const fetchStateList = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/state`
      );
      const data = await res.data;
      console.log("ccc");
      setState(data);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchDistricts = async () => {
    const params = {
      state: selectedState,
    };
    const paramString = new URLSearchParams(params).toString();
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/district?${paramString}`
      );
      const data = await res.data;
      setDistrict(data);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchjobrole = () => {
    axios
      // .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/designation`)
      .get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/master-jobrole`
      )
      .then((res) => {
        // setJobrole([...res.data, { label: "Others", value: "Others" }]);
        setJobrole([...res.data]);
      })
      .catch((e) => {
        // console.log(e);
      });
  };

  useEffect(() => {
    fetchStateList();
    fetchjobrole();
    fetchBanner();
  }, []);

  useEffect(() => {
    fetchDistricts();
  }, [selectedState]);

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const formOne = useFormik({
    enableReinitialize: true,
    initialValues: {
      org_name: "",
      address: "",
      state: "",
      district: "",
      //pincode: "",
      //cin: "",
      name: "",
      email: "",
      mob_num: "",
    },
    validationSchema: Yup.object().shape({
      org_name: Yup.string().required("Please enter organisation name"),
      address: Yup.string().notRequired(),
      state: Yup.string().required("Please enter state"),
      district: Yup.string().required("Please enter district"),
      /*
      pincode: Yup.string()
        .notRequired()
        .length(6, "Pincode should be 6 digit")
        .matches(/^[0-9]+$/, "Must be only digits"),
        
      cin: Yup.string()
        .notRequired()
        .length(21, "CIN should be 21 digit")
        .matches(
          /^([LUu]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/,
          "Enter valid CIN"
        ),
        */
      name: Yup.string().required("Please enter name"),
      email: Yup.string()
        .email("Please enter valid email")
        .required("Please enter email"),
      mob_num: Yup.string()
        .required("Please enter mobile number")
        .length(10, "Mobile number should be 10 digit")
        .matches(/^[0-9]+$/, "Must be only digits"),
    }),

    onSubmit: async (values, { resetForm }) => {
      const waitingPopup = Swal.fire({
        title: "Please wait...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      // setOpen1(true);
      //setIsLoading(true);
      setOrgName(values.org_name);
      setOrgAddress(values.address);
      setNewState(values.state);
      setNewDistrict(values.district);
      //setPinCode(values.pincode);
      //setCinNo(values.cin);
      setNewName(values.name);
      setNewEmail(values.email);
      setNewMobile(values.mob_num);
      //setOrgId(data['Job Provider ID'] ? data['Job Provider ID'] : "")
      //setOrgNo(data['Job Provider Mob'] ? data['Job Provider Mob'] : "")
      //setNewshow(true);
      if (query1 !== null) {
        try {
          const res = await axios.post(
            `${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/job_provider_form`,
            {
              jf_id: query1,
              // datasource:searchParams.get("id"),
              // currently deactive for prod live you id as maharastra
              // jf_id: "JOBFAIR-MAHARASHTRA",
              name_org: values.org_name,
              address: values.address,
              state: values.state,
              district: values.district,
              //pincode: pinCode,
              //cin: cinNo,
              spoc_name: values.name,
              spoc_phone: values.mob_num,
              spoc_email: values.email,
              //jp_requirements: jobTitle,
            }
          );
          const data = await res.data;
          setTimeout(() => {
            waitingPopup.close(); // to close swal
          }, 0);
          console.log(data);
          console.log(data["jpo_id"]);
          setJpId(data?.jpo_id);
          setNewshow(true);
          setNextStep(true);
          // handleNext();
          //setIsLoading(false);
          Swal.fire({
            icon: "success",
            title: "Registered Successfully",
            //text: "Something went wrong.",
            showConfirmButton: false,
            timer: 3000,
          }).then(() => {
            formOne.resetForm();
          });
        } catch (err) {
          console.log(err?.response?.data["Developer Message"], "abc");
          setTimeout(() => {
            waitingPopup.close(); // to close swal
          }, 0);
          //setIsLoading(false);
          Swal.fire({
            icon: "error",
            title: err?.response?.data["Developer Message"]
              ? err?.response?.data["Developer Message"]
              : "Something went wrong.",
            //text: "Something went wrong.",
            showConfirmButton: false,
            timer: 3000,
          });
          setNewshow(false);
          setNextStep(false);
          //setOpen1(false);
        }
      } else {
        try {
          const res = await axios.post(
            `${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/job_provider_form`,
            {
              // jf_id: query1,
              datasource: searchParams.get("id"),
              // currently deactive for prod live you id as maharastra
              // jf_id: "JOBFAIR-MAHARASHTRA",
              name_org: values.org_name,
              address: values.address,
              state: values.state,
              district: values.district,
              //pincode: pinCode,
              //cin: cinNo,
              spoc_name: values.name,
              spoc_phone: values.mob_num,
              spoc_email: values.email,
              //jp_requirements: jobTitle,
            }
          );
          const data = await res.data;
          setTimeout(() => {
            waitingPopup.close(); // to close swal
          }, 0);
          console.log(data);
          console.log(data["jpo_id"]);
          setJpId(data?.jpo_id);
          setNewshow(true);
          setNextStep(true);
          // handleNext();
          //setIsLoading(false);
          Swal.fire({
            icon: "success",
            title: "Registered Successfully",
            //text: "Something went wrong.",
            showConfirmButton: false,
            timer: 3000,
          }).then(() => {
            formOne.resetForm();
          });
        } catch (err) {
          console.log(err?.response?.data["Developer Message"], "abc");
          setTimeout(() => {
            waitingPopup.close(); // to close swal
          }, 0);
          //setIsLoading(false);
          Swal.fire({
            icon: "error",
            title: err?.response?.data["Developer Message"]
              ? err?.response?.data["Developer Message"]
              : "Something went wrong.",
            //text: "Something went wrong.",
            showConfirmButton: false,
            timer: 3000,
          });
          setNewshow(false);
          setNextStep(false);
          //setOpen1(false);
        }
      }
    },
  });

  const formTwo = async () => {
    //setIsLoading(true);
    const waitingPopup = Swal.fire({
      title: "Please wait...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    if (query1 !== null) {
      try {
        /*
        let formdata = new FormData()
        formdata.append("org_name", orgName)
        formdata.append("address", orgAddress)
        formdata.append("state", newState)
        formdata.append("district", newDistrict)
        formdata.append("pincode", pinCode)
        formdata.append("cin", cinNo)
        formdata.append("name", newName)
        formdata.append("mob_num", newMobile)
        formdata.append("email", newEmail)
        formdata.append("jp_requirements", jobTitle)
        */

        const res = await axios
          .post(
            `${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/job_provider_form`,
            {
              // org_name: orgName,
              // address: orgAddress,
              // state: newState,
              // district: newDistrict,
              // //pincode: pinCode,
              // //cin: cinNo,
              // name: newName,
              // mobnum: newMobile,
              // email: newEmail,
              jf_id: query1,
              // datasource:searchParams.get("id"),
              // jf_id: "JOBFAIR-MAHARASHTRA",
              jpo_id: jpId,
              job_details: jobTitle.map((v) => {
                return {
                  job_title: v.job_title,
                  job_role: v.job_role,
                  job_desc: v.job_desc,
                  count: v.count === "" ? 0 : v.count,
                };
              }),
            }
          )
          .then(() => {
            //setIsLoading(false);
            setTimeout(() => {
              waitingPopup.close(); // to close swal
            }, 0);
            setOrgName("");
            setOrgAddress("");
            setNewState("");
            setNewDistrict("");
            setPinCode("");
            setCinNo("");
            setNewName("");
            setNewEmail("");
            setNewMobile("");
            setJobTitle([
              {
                job_title: "",
                job_role: "",
                job_desc: "",
                count: "",
              },
            ]);
            setOpen(true);
            setNewshow(false);
            // setTimeout(() => (setOpen(false), formOne.resetForm()), 3000);
          });
        // Swal.fire({
        //   icon: "success",
        //   text: res.data?.status
        //     ? "Registration successful."
        //     : "Submitted successfully.",
        //   //text: "Something went wrong.",
        //   showConfirmButton: false,
        //   timer: 3000,
        // }).then(() => {
        //   formOne.resetForm();
        //   setOrgName("");
        //   setOrgAddress("");
        //   setNewState("");
        //   setNewDistrict("");
        //   setPinCode("");
        //   setCinNo("");
        //   setNewName("");
        //   setNewEmail("");
        //   setNewMobile("");
        //   setJobTitle([
        //     {
        //       job_role: "",
        //       job_desc: "",
        //       count: "",
        //     },
        //   ]);
        //   setNewshow(false);
        // });
        // if (res.data) {
        //   // formOne.resetForm();
        //   setIsLoading(false);
        //   setOrgName("");
        //   setOrgAddress("");
        //   setNewState("");
        //   setNewDistrict("");
        //   setPinCode("");
        //   setCinNo("");
        //   setNewName("");
        //   setNewEmail("");
        //   setNewMobile("");
        //   setJobTitle([
        //     {
        //       job_role: "",
        //       job_desc: "",
        //       count: "",
        //     },
        //   ]);
        //   setOpen(true);
        //   setNewshow(false);
        //   setTimeout(() => (setOpen(false), formOne.resetForm()), 3000);
        // }
      } catch (err) {
        //setIsLoading(false);
        Swal.fire({
          icon: "error",
          text: "Something went wrong.",
          //text: "Something went wrong.",
          showConfirmButton: false,
          timer: 3000,
        }).then(() => {
          formOne.handleReset();
          setTimeout(() => {
            waitingPopup.close(); // to close swal
          }, 0);
          setOrgName("");
          setOrgAddress("");
          setNewState("");
          setNewDistrict("");
          setPinCode("");
          setCinNo("");
          setNewName("");
          setNewEmail("");
          setNewMobile("");
          setJobTitle([
            {
              job_title: "",
              job_role: "",
              job_desc: "",
              count: "",
            },
          ]);
          setNewshow(false);
        });
      }
    } else {
      try {
        /*
      let formdata = new FormData()
      formdata.append("org_name", orgName)
      formdata.append("address", orgAddress)
      formdata.append("state", newState)
      formdata.append("district", newDistrict)
      formdata.append("pincode", pinCode)
      formdata.append("cin", cinNo)
      formdata.append("name", newName)
      formdata.append("mob_num", newMobile)
      formdata.append("email", newEmail)
      formdata.append("jp_requirements", jobTitle)
      */

        const res = await axios
          .post(
            `${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/job_provider_form`,
            {
              // org_name: orgName,
              // address: orgAddress,
              // state: newState,
              // district: newDistrict,
              // //pincode: pinCode,
              // //cin: cinNo,
              // name: newName,
              // mobnum: newMobile,
              // email: newEmail,
              // jf_id: query1,
              datasource: searchParams.get("id"),
              // jf_id: "JOBFAIR-MAHARASHTRA",
              jpo_id: jpId,
              job_details: jobTitle.map((v) => {
                return {
                  job_title: v.job_title,
                  job_role: v.job_role,
                  job_desc: v.job_desc,
                  count: v.count === "" ? 0 : v.count,
                };
              }),
            }
          )
          .then(() => {
            //setIsLoading(false);
            setTimeout(() => {
              waitingPopup.close(); // to close swal
            }, 0);
            setOrgName("");
            setOrgAddress("");
            setNewState("");
            setNewDistrict("");
            setPinCode("");
            setCinNo("");
            setNewName("");
            setNewEmail("");
            setNewMobile("");
            setJobTitle([
              {
                job_title: "",
                job_role: "",
                job_desc: "",
                count: "",
              },
            ]);
            setOpen(true);
            setNewshow(false);
            // setTimeout(() => (setOpen(false), formOne.resetForm()), 3000);
          });
        // Swal.fire({
        //   icon: "success",
        //   text: res.data?.status
        //     ? "Registration successful."
        //     : "Submitted successfully.",
        //   //text: "Something went wrong.",
        //   showConfirmButton: false,
        //   timer: 3000,
        // }).then(() => {
        //   formOne.resetForm();
        //   setOrgName("");
        //   setOrgAddress("");
        //   setNewState("");
        //   setNewDistrict("");
        //   setPinCode("");
        //   setCinNo("");
        //   setNewName("");
        //   setNewEmail("");
        //   setNewMobile("");
        //   setJobTitle([
        //     {
        //       job_role: "",
        //       job_desc: "",
        //       count: "",
        //     },
        //   ]);
        //   setNewshow(false);
        // });
        // if (res.data) {
        //   // formOne.resetForm();
        //   setIsLoading(false);
        //   setOrgName("");
        //   setOrgAddress("");
        //   setNewState("");
        //   setNewDistrict("");
        //   setPinCode("");
        //   setCinNo("");
        //   setNewName("");
        //   setNewEmail("");
        //   setNewMobile("");
        //   setJobTitle([
        //     {
        //       job_role: "",
        //       job_desc: "",
        //       count: "",
        //     },
        //   ]);
        //   setOpen(true);
        //   setNewshow(false);
        //   setTimeout(() => (setOpen(false), formOne.resetForm()), 3000);
        // }
      } catch (err) {
        //setIsLoading(false);
        Swal.fire({
          icon: "error",
          text: "Something went wrong.",
          //text: "Something went wrong.",
          showConfirmButton: false,
          timer: 3000,
        }).then(() => {
          formOne.handleReset();
          setTimeout(() => {
            waitingPopup.close(); // to close swal
          }, 0);
          setOrgName("");
          setOrgAddress("");
          setNewState("");
          setNewDistrict("");
          setPinCode("");
          setCinNo("");
          setNewName("");
          setNewEmail("");
          setNewMobile("");
          setJobTitle([
            {
              job_title: "",
              job_role: "",
              job_desc: "",
              count: "",
            },
          ]);
          setNewshow(false);
        });
      }
    }
  };

  return (
    <>
      <div>
        <div>
          <img
            style={{
              width: "100%",
              height: matchsmb ? "100%" : "360px",
              display: "block",
            }}
            src={
              searchParams.get("id") === "hindurojgaar"
                ? cover2
                : bannerImg === "" || count[0] === ""
                ? cover1
                : matchsmb
                ? mobBanner
                : bannerImg
            }
            // src="https://sapioanalytics.com/wp-content/uploads/2023/05/Mask-group-6.png"
            // className="logo-img"
          />
        </div>

        <div className="SDiv">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              flexDirection: "column",
              height: "100%",
            }}
          >
            {/* <SBox1> */}
            {/* <br /> */}
            {/* <STypography1 variant="body1">
              Job Provider Registration
            </STypography1> */}

            {newshow ? (
              <Box
                sx={
                  matchmd
                    ? {
                        width: "1050px",
                        background: "#FFFFFF",
                        boxShadow: "0px 2px 6px rgba(59, 80, 146, 0.25)",
                        borderRadius: "10px",
                        margin: "20px",
                        padding: "15px",
                      }
                    : {
                        minWidth: "300px",
                        background: "#FFFFFF",
                        boxShadow: "0px 2px 6px rgba(59, 80, 146, 0.25)",
                        margin: "20px",
                        padding: "15px",
                      }
                }
              >
                {/* <STypography2 variant="body1">
                Job Provider Registration
              </STypography2> */}
                {/* <Box sx={{ mt: 3, mb: 2 }}>
                  <STypography7 variant="body1">
                    Organisation Details
                  </STypography7>
                </Box> */}
                <Box
                  sx={{
                    maxWidth: "700px",
                    minWidth: "200px",
                    ml: "auto",
                    mr: "auto",
                    mb: 2,
                    mt: 2,
                  }}
                >
                  <Stepper activeStep={activeStep} sx={stepperStyle}>
                    {steps.map((label, index) => {
                      const stepProps = {};
                      const labelProps = {};
                      if (isStepSkipped(index)) {
                        stepProps.completed = false;
                      }
                      return (
                        <Step key={label} {...stepProps}>
                          <StepLabel {...labelProps}>
                            <STypography7 variant="body2">{label}</STypography7>
                          </StepLabel>
                        </Step>
                      );
                    })}
                  </Stepper>
                </Box>
                <Grid container spacing={2} sx={{ mb: 0.5, pl: 1 }}>
                  {jobTitle.map((input, index) => (
                    <>
                      <Grid container spacing={2} sx={{ mb: 0.5, mt: 0.5 }}>
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                          <SBox4>
                            <STypography4 variant="body1">
                              Job Title{" "}
                              <span
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "13px",
                                }}
                              >
                                (Optional)
                              </span>
                            </STypography4>

                            <TextField
                              fullWidth
                              name="job_title"
                              type="text"
                              placeholder="Enter Job Title"
                              value={input.job_title}
                              onChange={(event) => {
                                handleForm(
                                  index,
                                  event.target.name,
                                  event.target.value
                                );
                              }}
                            />
                          </SBox4>
                        </Grid>
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                          <SBox4>
                            <STypography4 variant="body1">
                              Job Role{" "}
                              <span
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "13px",
                                }}
                              >
                                (Optional)
                              </span>
                            </STypography4>

                            <Select
                              placeholder="Select"
                              options={jobrole}
                              onChange={(event) => {
                                handleForm(
                                  index,
                                  "job_role",
                                  event ? event.value : ""
                                );
                              }}
                              //value={{ label: formOne.values.state }}
                              styles={{
                                placeholder: (baseStyles, state) => ({
                                  ...baseStyles,
                                  fontFamily: "Inter",
                                  color: "rgba(0, 0, 0, 0.3)",
                                }),
                                menu: (baseStyles, state) => ({
                                  ...baseStyles,
                                  fontFamily: "Inter",
                                  fontSize: "15px",
                                  textAlign: "left",
                                }),
                              }}
                            />
                          </SBox4>
                        </Grid>

                        <Grid item lg={4} md={4} sm={6} xs={12}>
                          <SBox4>
                            <STypography4 variant="body1">
                              Total No.of Vacancies{" "}
                              <span
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "13px",
                                }}
                              >
                                (Optional)
                              </span>
                            </STypography4>

                            <TextField
                              fullWidth
                              name="count"
                              type="number"
                              placeholder="Enter No. of Vacancies"
                              value={input.count}
                              onChange={(event) => {
                                handleForm(
                                  index,
                                  event.target.name,
                                  event.target.value
                                );
                              }}
                            />
                          </SBox4>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <SBox4>
                            <STypography4 variant="body1">
                              Job Description{" "}
                              <span
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "13px",
                                }}
                              >
                                (Optional)
                              </span>
                            </STypography4>
                            <STypography4 variant="body1">
                              {" "}
                              <span
                                style={{
                                  fontWeight: "normal",
                                  fontSize: "13px",
                                }}
                              >
                                {`${input.job_desc.length}/150`}
                              </span>
                            </STypography4>

                            <TextField
                              fullWidth
                              name="job_desc"
                              type="text"
                              placeholder="Enter Job Description"
                              value={input.job_desc}
                              onChange={(event) => {
                                handleForm(
                                  index,
                                  event.target.name,
                                  event.target.value
                                );
                                // setCount((c) => c + 1);
                              }}
                              multiline
                              maxRows={5}
                              InputProps={{ inputProps: { maxLength: 150 } }}
                              // label="Max 150 characters"
                            />
                          </SBox4>
                        </Grid>
                        <hr />
                        {index > 0 && (
                          <Box sx={{ width: "100%", textAlign: "right" }}>
                            <SButton3
                              sx={{ width: "30px", marginBottom: "0px" }}
                              onClick={() => removeField(index)}
                            >
                              <DeleteOutlineIcon />
                            </SButton3>
                          </Box>
                        )}
                      </Grid>
                    </>
                  ))}
                </Grid>

                <SButton1 onClick={(e) => addFields(e)}>
                  <STypography6 variant="body1">Add Jobs</STypography6>
                </SButton1>
                <br />

                <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
                  <SButton2
                    onClick={() => {
                      setNewshow(false);
                      setNextStep(false);
                      setActiveStep(0);
                      //formOne.resetForm();
                      //formTwo.resetForm();
                      //formThree.resetForm();
                    }}
                  >
                    <STypography6 variant="body1">Cancel</STypography6>
                  </SButton2>

                  <SButton1
                    onClick={() => formTwo()}
                    //onClick={() => formTwo.handleSubmit()}
                    //onClick={() => setNewshow(true)}
                  >
                    <STypography6 variant="body1">Submit</STypography6>
                  </SButton1>
                </Box>
              </Box>
            ) : (
              <Box
                sx={
                  matchmd
                    ? {
                        width: "1050px",
                        background: "#FFFFFF",
                        boxShadow: "0px 2px 6px rgba(59, 80, 146, 0.25)",
                        borderRadius: "10px",
                        margin: "20px",
                        padding: "15px",
                      }
                    : {
                        minWidth: "300px",
                        background: "#FFFFFF",
                        boxShadow: "0px 2px 6px rgba(59, 80, 146, 0.25)",
                        marginTop: "20px",
                        margin: "20px",
                        padding: "15px",
                      }
                }
              >
                {/* <STypography2 variant="body1">
                Job Provider Registration
              </STypography2> */}
                {/* <Box sx={{ mt: 3, mb: 2 }}>
                  <STypography7 variant="body1">
                    Organisation Details
                  </STypography7>
                </Box> */}
                <Box
                  sx={{
                    maxWidth: "700px",
                    minWidth: "200px",
                    ml: "auto",
                    mr: "auto",
                    mb: 2,
                    mt: 2,
                  }}
                >
                  <Stepper activeStep={activeStep} sx={stepperStyle}>
                    {steps.map((label, index) => {
                      const stepProps = {};
                      const labelProps = {};
                      if (isStepSkipped(index)) {
                        stepProps.completed = false;
                      }
                      return (
                        <Step key={label} {...stepProps}>
                          <StepLabel {...labelProps}>
                            <STypography7 variant="body2">{label}</STypography7>
                          </StepLabel>
                        </Step>
                      );
                    })}
                  </Stepper>
                </Box>

                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <SBox4>
                      <STypography4 variant="body1">
                        Name of Organisation{" "}
                        <sup style={{ color: "red", fontSize: "8px" }}>*</sup>
                      </STypography4>

                      <TextField
                        // disabled={nextStep}
                        type="text"
                        fullWidth
                        //onBlur={formOne.handleBlur}
                        id="org_name"
                        name="org_name"
                        size="small"
                        onChange={formOne.handleChange}
                        value={formOne.values.org_name}
                      />

                      {formOne.touched.org_name && formOne.errors.org_name ? (
                        <STypography5 variant="caption">
                          {formOne.errors.org_name}
                        </STypography5>
                      ) : null}
                    </SBox4>
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <SBox4>
                      <STypography4 variant="body1">Address </STypography4>

                      <TextField
                        type="text"
                        fullWidth
                        //onBlur={formOne.handleBlur}
                        id="address"
                        name="address"
                        size="small"
                        onChange={formOne.handleChange}
                        value={formOne.values.address}
                      />

                      {formOne.touched.address && formOne.errors.address ? (
                        <STypography5 variant="caption">
                          {formOne.errors.address}
                        </STypography5>
                      ) : null}
                    </SBox4>
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <SBox4>
                      <STypography4 variant="body1">
                        State{" "}
                        <sup style={{ color: "red", fontSize: "8px" }}>*</sup>
                      </STypography4>

                      <Box sx={{ mt: 1 }}>
                        <Select
                          placeholder="Select"
                          options={state}
                          onChange={(e) => {
                            formOne.setFieldValue("state", e ? e.value : "");
                            setSelectedState(e ? e.value : "");
                          }}
                          value={{ label: formOne.values.state }}
                          id="state"
                          name="state"
                          styles={{
                            placeholder: (baseStyles, state) => ({
                              ...baseStyles,
                              fontFamily: "Inter",
                              color: "rgba(0, 0, 0, 0.3)",
                            }),
                            menu: (baseStyles, state) => ({
                              ...baseStyles,
                              fontFamily: "Inter",
                              fontSize: "15px",
                              textAlign: "left",
                            }),
                          }}
                        />
                      </Box>

                      {formOne.touched.state && formOne.errors.state ? (
                        <STypography5 variant="caption">
                          {formOne.errors.state}
                        </STypography5>
                      ) : null}
                    </SBox4>
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <SBox4>
                      <STypography4 variant="body1">
                        District{" "}
                        <sup style={{ color: "red", fontSize: "8px" }}>*</sup>
                      </STypography4>

                      <Box sx={{ mt: 1 }}>
                        <Select
                          placeholder="Select"
                          options={district}
                          onChange={(e) => {
                            formOne.setFieldValue("district", e.value);
                          }}
                          value={{ label: formOne.values.district }}
                          id="district"
                          name="district"
                          styles={{
                            placeholder: (baseStyles, state) => ({
                              ...baseStyles,
                              fontFamily: "Inter",
                              color: "rgba(0, 0, 0, 0.3)",
                            }),
                            menu: (baseStyles, state) => ({
                              ...baseStyles,
                              fontFamily: "Inter",
                              fontSize: "15px",
                              textAlign: "left",
                            }),
                          }}
                        />
                      </Box>

                      {formOne.touched.district && formOne.errors.district ? (
                        <STypography5 variant="caption">
                          {formOne.errors.district}
                        </STypography5>
                      ) : null}
                    </SBox4>
                  </Grid>

                  {/*
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <SBox4>
                  <STypography4 variant="body1">
                    Pin Code{" "}
                  </STypography4>

                  <TextField
                    type="tel"
                    inputProps={{
                      pattern:
                        "/^([+]?[s0-9]+)?(d{3}|[(]?[0-9]+[)])?([-]?[s]?[0-9])+$/",
                      maxLength: "6",
                      minLength: "6",
                      error: false,
                    }}
                    fullWidth
                    id="pincode"
                    name="pincode"
                    size="small"
                    // helperText="Select valid 6 digit pincode"
                    onChange={formOne.handleChange}
                    value={formOne.values.pincode}
                  />

                  {formOne.touched.pincode && formOne.errors.pincode ? (
                    <STypography5 variant="caption">
                      {formOne.errors.pincode}
                    </STypography5>
                  ) : null}
                </SBox4>
              </Grid>
              */}

                  {/*
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <SBox4>
                  <STypography4 variant="body1">
                    CIN
                  </STypography4>

                  <TextField
                    // disabled={nextStep}
                    type="tel"
                    inputProps={{
                      pattern:
                        "/^([+]?[s0-9]+)?(d{3}|[(]?[0-9]+[)])?([-]?[s]?[0-9])+$/",
                      maxLength: "21",
                      minLength: "21",
                      error: false,
                    }}
                    fullWidth
                    id="cin"
                    name="cin"
                    size="cin"
                    onChange={formOne.handleChange}
                    value={formOne.values.cin}
                  />

                  {formOne.touched.cin && formOne.errors.cin ? (
                    <STypography5 variant="caption">
                      {formOne.errors.cin}
                    </STypography5>
                  ) : null}
                </SBox4>
              </Grid>
              */}
                </Grid>
                <br />

                {/* <Box sx={{ mt: 3, mb: 2 }}>
                  <STypography7 variant="body1">Contact Details</STypography7>
                </Box> */}

                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <SBox4>
                      <STypography4 variant="body1">
                        SPOC Name{" "}
                        <sup style={{ color: "red", fontSize: "8px" }}>*</sup>
                      </STypography4>

                      <TextField
                        // disabled={nextStep}
                        type="text"
                        fullWidth
                        //onBlur={formOne.handleBlur}
                        id="name"
                        name="name"
                        size="small"
                        onChange={formOne.handleChange}
                        value={formOne.values.name}
                      />

                      {formOne.touched.name && formOne.errors.name ? (
                        <STypography5 variant="caption">
                          {formOne.errors.name}
                        </STypography5>
                      ) : null}
                    </SBox4>
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <SBox4>
                      <STypography4 variant="body1">
                        SPOC Email{" "}
                        <sup style={{ color: "red", fontSize: "8px" }}>*</sup>
                      </STypography4>

                      <TextField
                        // disabled={nextStep}
                        type="email"
                        fullWidth
                        //onBlur={formOne.handleBlur}
                        id="email"
                        name="email"
                        size="small"
                        onChange={formOne.handleChange}
                        value={formOne.values.email}
                      />

                      {formOne.touched.email && formOne.errors.email ? (
                        <STypography5 variant="caption">
                          {formOne.errors.email}
                        </STypography5>
                      ) : null}
                    </SBox4>
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <SBox4>
                      <STypography4 variant="body1">
                        SPOC Mobile Number{" "}
                        <sup style={{ color: "red", fontSize: "8px" }}>*</sup>
                      </STypography4>

                      <TextField
                        // disabled={nextStep}
                        type="tel"
                        inputProps={{
                          pattern:
                            "/^([+]?[s0-9]+)?(d{3}|[(]?[0-9]+[)])?([-]?[s]?[0-9])+$/",
                          maxLength: "10",
                          minLength: "10",
                          error: false,
                        }}
                        fullWidth
                        id="mob_num"
                        name="mob_num"
                        size="small"
                        onChange={formOne.handleChange}
                        value={formOne.values.mob_num}
                      />

                      {formOne.touched.mob_num && formOne.errors.mob_num ? (
                        <STypography5 variant="caption">
                          {formOne.errors.mob_num}
                        </STypography5>
                      ) : null}
                    </SBox4>
                  </Grid>
                </Grid>
                <br />

                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <SButton1
                    onClick={() =>
                      // nextStep
                      //   ? () => {
                      //       setNewshow(true);
                      //     }
                      //   : () => {
                      //       // setSubmitformOne(true);
                      //       formOne.handleSubmit();
                      //     }
                      formOne.handleSubmit()
                    }
                    //onClick={() => setNewshow(true)}
                  >
                    <STypography6 variant="body1">Next</STypography6>
                  </SButton1>

                  <SButton2
                    onClick={() => {
                      //setShow(0);
                      formOne.resetForm();
                      setNextStep(false);
                      setActiveStep(0);
                      //formTwo.resetForm();
                      //formThree.resetForm();
                    }}
                  >
                    <STypography6 variant="body1">Cancel</STypography6>
                  </SButton2>
                </Box>
              </Box>
            )}
            {/* </SBox1> */}
          </Box>
        </div>
      </div>
      <Dialog
        open={open}
        //onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          sx={
            matchsm
              ? { textAlign: "center", width: "600px" }
              : { textAlign: "center", maxWidth: "600px" }
          }
        >
          <Box
            sx={
              matchsm
                ? {
                    width: "500px",
                    m: 2,
                    ml: "auto",
                    mr: "auto",
                    p: 1,
                    border: "3px dashed #A7A7A7",
                    minHeight: "300px",
                  }
                : {
                    maxWidth: "500px",
                    m: 2,
                    ml: "auto",
                    mr: "auto",
                    p: 1,
                    border: "3px dashed #A7A7A7",
                    minHeight: "300px",
                  }
            }
          >
            <img src={img4} height={"70px"} alt="" />
            <STypography11 variant="body2">
              Form submited successfully
            </STypography11>
            <hr />

            <STypography4 variant="body1">
              Thank you for registering with us!
            </STypography4>

            <SButton3
              onClick={() => {
                setActiveStep(0);
                formOne.resetForm();
                // handleClose();
                // window.location.reload();
                setOpen(false);
              }}
            >
              <STypography6 variant="body1">
                Go back to form <ArrowForwardIcon />
              </STypography6>
            </SButton3>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        open={open1}
        //onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          sx={
            matchsm
              ? { textAlign: "center", width: "600px" }
              : { textAlign: "center", maxWidth: "600px" }
          }
        >
          <Box
            sx={
              matchsm
                ? {
                    width: "500px",
                    m: 2,
                    ml: "auto",
                    mr: "auto",
                    p: 1,
                    border: "3px dashed #A7A7A7",
                    minHeight: "300px",
                  }
                : {
                    maxWidth: "500px",
                    m: 2,
                    ml: "auto",
                    mr: "auto",
                    p: 1,
                    border: "3px dashed #A7A7A7",
                    minHeight: "300px",
                  }
            }
          >
            <img src={img4} height={"70px"} alt="" />
            <STypography11 variant="body2">
              Details saved successfully
            </STypography11>
            <hr />

            <STypography4 variant="body1">
              “You are just one step away to start your journey”
            </STypography4>

            <STypography9 variant="body1">
              Please do not close the window or go back
            </STypography9>
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog
        open={isLoading}
        //onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          sx={
            matchsm
              ? { textAlign: "center", width: "600px" }
              : { textAlign: "center", maxWidth: "600px" }
          }
        >
          <Box
            sx={
              matchsm
                ? {
                    width: "500px",
                    m: 2,
                    ml: "auto",
                    mr: "auto",
                    p: 1,
                    border: "3px dashed #A7A7A7",
                    minHeight: "300px",
                  }
                : {
                    maxWidth: "500px",
                    m: 2,
                    ml: "auto",
                    mr: "auto",
                    p: 1,
                    border: "3px dashed #A7A7A7",
                    minHeight: "300px",
                  }
            }
          >
            {/* <img src={img4} height={"70px"} alt="" /> */}
            <STypography12 variant="body2">Please wait!</STypography12>
            {/* <hr /> */}
            <STypography13 variant="body1">
              Your personal details are being Saved
            </STypography13>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "30px",
                marginBottom: "20px",
              }}
            >
              <TailSpin
                height="50"
                width="50"
                color="#3996DA"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </Box>
            <STypography14 variant="body1">
              “You are Just one step away to start Your Journey”
            </STypography14>
            <STypography9 variant="body1">
              Please do not close the window or Go back{" "}
            </STypography9>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default RegistrationForm;
